// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampChirurgie = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M85.4 3.5l2.6-.2.6 7.2a4 4 0 011.2-1.8 3.4 3.4 0 012.1-.8 3.7 3.7 0 013 1 5.3 5.3 0 011.3 3.4l.6 7.8-2.5.2-.6-7.5c-.1-1.9-1-2.7-2.5-2.6a3.6 3.6 0 00-1 .2 2.5 2.5 0 00-.9.4 1.9 1.9 0 00-.5.7 2 2 0 00-.2 1l.7 8.2-2.6.2z' />
      <path d='M101.9 7.8l2.6.1-.3 12.3h-2.6zm1.3-2.1a1.6 1.6 0 01-1.1-.4 1.3 1.3 0 01-.3-1v-.4a1.4 1.4 0 01.3-1 1.6 1.6 0 011.2-.3 1.6 1.6 0 011.2.4 1.4 1.4 0 01.3 1v.4a1.4 1.4 0 01-.4 1 1.5 1.5 0 01-1.2.3z' />
      <path d='M109 20.6l1.6-12.2 2.5.3-.3 2.4h.1a3.3 3.3 0 011.3-1.5 3.4 3.4 0 012.4-.4h.7l-.3 2.5-1-.1a4.5 4.5 0 00-2.4.2 1.6 1.6 0 00-1 1.3l-1 7.8z' />
      <path d='M126.9 22.5h-.1a4.5 4.5 0 01-.7.7 2.8 2.8 0 01-.9.5 3.2 3.2 0 01-1.1.1 4.5 4.5 0 01-1.3-.2 3.7 3.7 0 01-2.4-2.1 5.3 5.3 0 010-3.7l2.6-7.4 2.4.8-2.4 7.1c-.6 1.8-.1 3 1.4 3.5a3.5 3.5 0 001 .2 2.6 2.6 0 00.9-.1 2 2 0 00.8-.5 2 2 0 00.5-.8l2.6-7.8 2.4.9-3.9 11.6-2.4-.8z' />
      <path d='M133.2 27l5.6-10.9 2.3 1.2-1.1 2.1h.1a3.3 3.3 0 011.7-1 3.4 3.4 0 012.4.5l.6.3-1.1 2.2-1-.5a4.7 4.7 0 00-2.3-.6 1.6 1.6 0 00-1.3 1l-3.6 7z' />
      <path d='M150 38.7a3.2 3.2 0 01-2.9 1.5 9 9 0 01-4.4-2 13.8 13.8 0 01-2-1.6 5.7 5.7 0 01-1-1.5 2.6 2.6 0 01-.4-1.3 2.5 2.5 0 01.5-1.2 2.2 2.2 0 011.4-1 3 3 0 011.7.2l.2-.2a1.8 1.8 0 010-2.3 1.8 1.8 0 011.3-.9 3.9 3.9 0 011.7.4v-.1a4 4 0 01-.5-2.2 4 4 0 01.8-2.1 4 4 0 012.8-1.7 5.2 5.2 0 013.6 1.1 5.9 5.9 0 011.6 1.6l.2-.3a1.9 1.9 0 011-.8 1.5 1.5 0 011.4.3l1.5 1.1-1.2 1.7-2-1.4-.3.4a3.7 3.7 0 01.2 1.9 4.2 4.2 0 01-.7 1.8 3.9 3.9 0 01-2.8 1.7 5.3 5.3 0 01-3.7-1.1 6.1 6.1 0 01-1.2-1 2.6 2.6 0 00-1-.2 1 1 0 00-.7.4.7.7 0 000 .9 3 3 0 001 .9l2.4 1.7a4.9 4.9 0 012.1 2.7 3.2 3.2 0 01-.6 2.6zm-2.1-1.2a1.3 1.3 0 00.2-1 3 3 0 00-1.3-1.4l-3.3-2.4a1.6 1.6 0 00-1.5.7 1.5 1.5 0 00-.3 1.3 3.5 3.5 0 001.4 1.6l1.3.9q2.5 1.7 3.5.3zm1.2-8.3a2.7 2.7 0 001.7.6 1.9 1.9 0 001.4-1l.3-.3a1.9 1.9 0 00.4-1.7 2.7 2.7 0 00-1.1-1.4 2.7 2.7 0 00-1.8-.6 1.9 1.9 0 00-1.4 1l-.2.3a2 2 0 00-.5 1.7 2.7 2.7 0 001.2 1.4z' />
      <path d='M161.5 30.9l1.9 1.8-8.5 8.9-1.9-1.8zm2.5-.7a1.6 1.6 0 01-.6-1 1.4 1.4 0 01.4-1l.3-.4a1.4 1.4 0 01.9-.4 1.6 1.6 0 011.1.5 1.5 1.5 0 01.6 1.1 1.3 1.3 0 01-.4 1l-.3.2a1.3 1.3 0 01-1 .5 1.5 1.5 0 01-1-.5z' />
      <path d='M161.4 48.8a6 6 0 01-1.2-2.1 4.9 4.9 0 01-.2-2.2 5.8 5.8 0 01.8-2.2 8.3 8.3 0 011.7-2 8.7 8.7 0 012.3-1.4 5.9 5.9 0 012.3-.5 4.9 4.9 0 012.1.5 5.7 5.7 0 011.9 1.5 5.5 5.5 0 011.2 2 4.7 4.7 0 01.1 2.2 5.7 5.7 0 01-.8 2 8.4 8.4 0 01-1.6 1.9l-.7.6-5.6-6.4-.3.3a3.3 3.3 0 00-1.2 2.1 3.2 3.2 0 001 2.4 3.5 3.5 0 001.6 1.2 4.4 4.4 0 001.8 0l-.1 2.2a4.9 4.9 0 01-2.7-.3 6.5 6.5 0 01-2.4-1.9zm8.2-7.1a3 3 0 00-1-.8 2.6 2.6 0 00-1.2-.3 2.8 2.8 0 00-1.1.2 3.7 3.7 0 00-1.1.7l-.2.1 3.8 4.3.2-.1a3.4 3.4 0 001.2-2 2.5 2.5 0 00-.6-2.1z' />
      <path d='M187 54.6l1 2.4-6.6 3v.1a3.3 3.3 0 012.2.5 3.8 3.8 0 011.4 1.6 4.3 4.3 0 010 4 9.4 9.4 0 01-8.7 4 4.3 4.3 0 01-3-2.7 3.7 3.7 0 01-.3-2.2 3.4 3.4 0 011-1.9l-2 .8-1-2.4zM175 66a2.8 2.8 0 001.7 1.6 3.2 3.2 0 002.4-.2l2-.9a3.2 3.2 0 001.7-1.6 2.8 2.8 0 00-.1-2.4 3.3 3.3 0 00-1.4-1.5 1.8 1.8 0 00-1.7-.1l-3.8 1.7a1.8 1.8 0 00-1 1.4 3.2 3.2 0 00.2 2z' />
      <path d='M180.7 82.5a4.7 4.7 0 01-1-.3 3.1 3.1 0 01-.8-.5 3.4 3.4 0 01-.7-.8 4.9 4.9 0 01-.5-1.3 3.7 3.7 0 01.5-3.1 5.3 5.3 0 013.2-1.9L189 73l.6 2.5-7.3 1.7q-2.8.6-2.3 3a3.7 3.7 0 00.4.9 2.4 2.4 0 00.5.7 2 2 0 00.8.4 1.9 1.9 0 001 0l8-1.8.6 2.5-12 2.8-.6-2.6z' />
      <path d='M180.2 95a5.8 5.8 0 01.3-2.3 4.6 4.6 0 011.1-1.9 6 6 0 012-1.2 9.7 9.7 0 015.3-.4 5.9 5.9 0 012 1 4.6 4.6 0 011.5 1.6 6 6 0 01.6 2.3 5.3 5.3 0 01-.6 3 4.8 4.8 0 01-2 2l-1.2-2a2.7 2.7 0 001.3-1 2.7 2.7 0 00.4-1.8 2.6 2.6 0 00-1-2 3.5 3.5 0 00-2.4-.6l-2.1.2a3.5 3.5 0 00-2.3.9 2.6 2.6 0 00-.7 2.2 2.8 2.8 0 00.7 1.7 3.9 3.9 0 001.5 1l-.9 2a4.9 4.9 0 01-2.5-1.7 5.3 5.3 0 01-1-3z' />
      <path d='M180 108.1a5.7 5.7 0 01.7-2.3 4.7 4.7 0 011.4-1.7 5.7 5.7 0 012.1-.9 8.6 8.6 0 012.7-.2 8.8 8.8 0 012.6.6 6 6 0 012 1.2 4.7 4.7 0 011 1.9 5.8 5.8 0 01.4 2.4 5.2 5.2 0 01-1 3 4.8 4.8 0 01-2.4 1.5l-.8-2.2a2.5 2.5 0 001.4-.8 2.7 2.7 0 00.6-1.6 2.6 2.6 0 00-.7-2.2 3.5 3.5 0 00-2.2-1l-2.2-.1a3.5 3.5 0 00-2.4.5 2.6 2.6 0 00-1 2.1 2.7 2.7 0 00.5 1.8 3.8 3.8 0 001.3 1.2l-1.2 1.9a5 5 0 01-2.2-2 5.3 5.3 0 01-.5-3z' />
      <path d='M176.9 125.2a2 2 0 011-1.3 2.6 2.6 0 011.6-.2v-.2a3 3 0 01-1.4-1.7 4.3 4.3 0 01.1-2.3 4 4 0 011.8-2.6 3.5 3.5 0 012.9-.3 3.1 3.1 0 012.2 2 6.8 6.8 0 01-.1 4l-.7 2.2 1 .3a2.4 2.4 0 002-.1 2.7 2.7 0 001-1.7 3.1 3.1 0 000-1.9 4.2 4.2 0 00-.8-1.4l1.7-1.1a4.9 4.9 0 011.2 2.1 5.6 5.6 0 01-.1 3 5.5 5.5 0 01-2.1 3.3 4 4 0 01-3.4.3l-6-1.6-.3 1.3-2-.6zm3-4.5a3.4 3.4 0 000 2 1.6 1.6 0 001.1 1.1l1.7.5.6-2c.5-1.8.2-2.8-.8-3l-.4-.2a1.5 1.5 0 00-1.4.2 2.5 2.5 0 00-.8 1.4z' />
      <path d='M174.1 132.5a2.6 2.6 0 011.5-1.5 2.6 2.6 0 012 .2l13.6 6.1-1.1 2.4-14-6.4-.7 1.6-2-.9z' />
      <path d='M169.3 141.4a6.1 6.1 0 011.5-1.9 5 5 0 012-.9 5.9 5.9 0 012.4 0 8.4 8.4 0 012.5 1 8.3 8.3 0 012 1.7 5.7 5.7 0 011.3 2 5 5 0 01.2 2.2 6 6 0 01-.8 2.3 5.5 5.5 0 01-1.6 1.8 4.7 4.7 0 01-2 .8 5.7 5.7 0 01-2.2 0 8.1 8.1 0 01-2.2-1l-.9-.4 4.3-7.4-.4-.2a3.3 3.3 0 00-2.4-.4 3.1 3.1 0 00-2 1.6 3.4 3.4 0 00-.5 2 4.3 4.3 0 00.5 1.8l-2 .5a4.8 4.8 0 01-.7-2.6 6.6 6.6 0 011-3zm9.4 5.4a3 3 0 00.4-1.2 2.8 2.8 0 00-.1-1.2 3 3 0 00-.6-1 4 4 0 00-1-.8h-.1l-2.9 5h.2a3.4 3.4 0 002.4.5 2.5 2.5 0 001.7-1.3z' />
      <path d='M156.4 157.8a5.8 5.8 0 012-1.3 5 5 0 012.2-.3 5.8 5.8 0 012.2.7 8.6 8.6 0 012 1.7 8.4 8.4 0 011.6 2.2 5.7 5.7 0 01.6 2.2 5 5 0 01-.4 2.2 6.2 6.2 0 01-1.4 2 5.7 5.7 0 01-2 1.3 4.7 4.7 0 01-2.1.2 5.6 5.6 0 01-2.1-.7 8 8 0 01-1.9-1.5l-.7-.7 6.1-5.8-.2-.3a3.2 3.2 0 00-2.2-1 3.1 3.1 0 00-2.4 1 3.6 3.6 0 00-1 1.6 4.4 4.4 0 000 1.9h-2.1a4.8 4.8 0 01.2-2.7 6.4 6.4 0 011.6-2.7zm7.5 7.9a3 3 0 00.7-1 2.8 2.8 0 00.2-1.1 3 3 0 00-.3-1.2 4 4 0 00-.7-1l-.1-.2-4.1 4 .1.1a3.4 3.4 0 002.1 1.2 2.5 2.5 0 002-.8z' />
      <path d='M146.7 166.3a2.4 2.4 0 013.8.6l4.3 6.1 1.6-1 1.2 1.6-.9.6a1.2 1.2 0 00-.5.8 1.4 1.4 0 00.3.9l1.3 1.7-2 1.4-1.9-2.7-2 1.5-1.3-1.8 2.1-1.5-4.7-6.6-2 1.4-1.2-1.7z' />
      <path d='M135.6 173.6l4 11.7-2.5.8-.6-2h-.1a5.6 5.6 0 01-.2 1 3.2 3.2 0 01-.4 1 2.8 2.8 0 01-.7.7 4 4 0 01-1.1.6 4.1 4.1 0 01-2.3.1 3.5 3.5 0 01-2-1.3 3.6 3.6 0 01-.7 2 3.8 3.8 0 01-2 1.5 3.5 3.5 0 01-3.1-.2 5.5 5.5 0 01-2.1-3l-2.5-7.4 2.4-.8 2.4 7a3.6 3.6 0 001.2 2 2 2 0 001.9 0 3.7 3.7 0 00.8-.3 2.5 2.5 0 00.6-.6 2 2 0 00.3-.8 2 2 0 000-1l-2.7-7.8 2.4-.9 2.4 7.1c.6 1.8 1.7 2.5 3 2a3.2 3.2 0 00.9-.4 2.6 2.6 0 00.6-.6 2 2 0 00.4-.8 2 2 0 00-.1-1l-2.7-7.8z' />
      <path d='M105.6 181a2 2 0 011.6.4 2.6 2.6 0 01.9 1.4h.1a3 3 0 011-1.9 4.2 4.2 0 012.3-.9 4 4 0 013 .7 3.5 3.5 0 011.3 2.5 3.1 3.1 0 01-1 2.9 6.8 6.8 0 01-3.7 1.4l-2.2.3.1 1a2.4 2.4 0 00.8 1.6 2.6 2.6 0 002 .4 3 3 0 001.7-.7 4.2 4.2 0 001-1.3l1.7 1.2a5 5 0 01-1.5 1.9 5.5 5.5 0 01-2.8 1 5.4 5.4 0 01-3.7-.7 4 4 0 01-1.7-3l-.8-6h-1.3l-.2-2zm5.4 1a3.5 3.5 0 00-2 .8 1.7 1.7 0 00-.5 1.6l.2 1.7 2.2-.2c1.7-.3 2.6-1 2.4-2v-.5a1.5 1.5 0 00-.7-1.1 2.6 2.6 0 00-1.6-.2z' />
      <path d='M101.8 181.5l-4.8 5.8 3.7 6.4-3-.2-2.3-4.5h-.1l-3.1 4-2.8-.2 4.7-5.7-3.8-6.5 3 .2 2.5 4.7 3.3-4.3z' />
      <path d='M83 194a1.5 1.5 0 011 .6 1.4 1.4 0 01.3 1v.4a1.3 1.3 0 01-.6.9 1.6 1.6 0 01-1.2.1 1.5 1.5 0 01-1.1-.6 1.4 1.4 0 01-.2-1v-.3a1.4 1.4 0 01.6-1 1.6 1.6 0 011.2-.1zm1.7-2l-2.6-.4 2.2-12 2.5.4z' />
      <path d='M77 177.7a2.6 2.6 0 011.7 1.2 2.5 2.5 0 01.1 2l-4 14.4-2.5-.7 4.2-14.8-1.7-.5.6-2z' />
      <path d='M69.7 175.1a2.3 2.3 0 011.5 3.4l-5.5 13.9-2.4-1 5.7-14.3-1.6-.6.8-2z' />
      <path d='M60.8 170.5a5.9 5.9 0 011.8 1.5 5 5 0 01.9 2 6 6 0 010 2.3 8 8 0 01-1 2.5 8.4 8.4 0 01-1.7 2.2 5.9 5.9 0 01-2 1.2 4.8 4.8 0 01-2.2.2 6 6 0 01-2.3-.8 5.7 5.7 0 01-1.7-1.5 5 5 0 01-1-2 6 6 0 010-2.3 8.5 8.5 0 011-2.5 8.7 8.7 0 011.7-2.1 6.2 6.2 0 012-1.2 4.9 4.9 0 012.2-.3 6 6 0 012.3.8zm-1.1 1.8a2.8 2.8 0 00-2.3-.3 3.3 3.3 0 00-1.9 1.7l-1 1.8a3.3 3.3 0 00-.5 2.5 2.9 2.9 0 001.4 1.8 2.8 2.8 0 002.3.3 3.3 3.3 0 001.9-1.7l1-1.8a3.3 3.3 0 00.5-2.5 2.8 2.8 0 00-1.4-1.8z' />
      <path d='M49 170.2l-1.6 2-5.2-4 1.7-2z' />
      <path d='M36.3 165l1.3 1.4-1.5 1.4-1.3-1.3-1.5 1.4a3.3 3.3 0 01-2.2 1 3 3 0 01-2.2-1.1l-1.4-1.5L29 165l1.8 1.9 2.2-2.2-1.8-1.8 1.6-1.5 1.7 1.9 7.4-7 1.8 1.8z' />
      <path d='M32.6 144.4a2 2 0 01.4 1.6 2.6 2.6 0 01-.8 1.4v.1a3 3 0 012.2 0 4.2 4.2 0 011.8 1.6 4 4 0 011 3 3.5 3.5 0 01-1.6 2.4 3.1 3.1 0 01-3 .5 7 7 0 01-3-2.6l-1.3-1.9-.9.6a2.4 2.4 0 00-1 1.6 2.6 2.6 0 00.6 1.9 3 3 0 001.4 1.1 4.1 4.1 0 001.6.3l-.3 2a4.8 4.8 0 01-2.4-.4 5.5 5.5 0 01-2.2-2A5.4 5.4 0 0124 152a4 4 0 011.9-2.9l5-3.6-.8-1 1.7-1.2zm1.6 5.2a3.5 3.5 0 00-1.6-1.3 1.7 1.7 0 00-1.6.2l-1.4 1 1.2 1.8c1 1.4 2 1.9 3 1.2l.3-.2a1.4 1.4 0 00.7-1.2 2.5 2.5 0 00-.6-1.4z' />
      <path d='M28 135.7a6 6 0 01.7 2.3 4.6 4.6 0 01-.4 2.1A6 6 0 0127 142a8.7 8.7 0 01-2.2 1.6 8.6 8.6 0 01-2.5.8 5.9 5.9 0 01-2.3 0 4.6 4.6 0 01-2-1 5.7 5.7 0 01-1.5-1.9 5 5 0 01-.6-3 4.7 4.7 0 011.2-2.6l1.9 1.5a2.7 2.7 0 00-.8 1.4 2.7 2.7 0 00.3 1.8 2.6 2.6 0 001.8 1.5 3.5 3.5 0 002.4-.4l2-1a3.5 3.5 0 001.6-1.7 2.6 2.6 0 00-.2-2.3 2.7 2.7 0 00-1.3-1.4 4 4 0 00-1.8-.3v-2.2a4.8 4.8 0 013 .6 5.4 5.4 0 012 2.3z' />
      <path d='M10.3 131.4a1.6 1.6 0 010 1.2 1.3 1.3 0 01-.8.7l-.4.1a1.3 1.3 0 01-1 0 1.5 1.5 0 01-.7-1 1.5 1.5 0 010-1.2 1.4 1.4 0 01.8-.6l.4-.2a1.4 1.4 0 011 0 1.5 1.5 0 01.7 1zm2.5.5l-.8-2.4 11.6-4 .9 2.5z' />
      <path d='M20.4 112a2 2 0 01-.2 1.7 2.6 2.6 0 01-1.4 1 3 3 0 012 1 4.2 4.2 0 011 2.2 4 4 0 01-.5 3 3.6 3.6 0 01-2.4 1.5 3.1 3.1 0 01-3-.8 6.9 6.9 0 01-1.6-3.7l-.4-2.2-1 .2a2.4 2.4 0 00-1.6 1 2.6 2.6 0 00-.3 2 3 3 0 00.8 1.6 4 4 0 001.4 1l-1.1 1.7a5 5 0 01-2-1.4 5.5 5.5 0 01-1.2-2.8 5.4 5.4 0 01.5-3.8 4 4 0 012.9-1.8l6-1.1-.2-1.3 2.1-.4zm-.7 5.5a3.5 3.5 0 00-.9-1.9 1.7 1.7 0 00-1.5-.5l-1.8.3.4 2.2c.3 1.7 1 2.5 2.1 2.3h.4a1.4 1.4 0 001.2-.8 2.5 2.5 0 00.1-1.6z' />
      <path d='M19.7 104.3a2.6 2.6 0 01-.5 2 2.6 2.6 0 01-1.8.8l-14.9 1.2-.2-2.6 15.3-1.2v-1.7l2-.1z' />
      <path d='M20.2 94.2a6 6 0 01-.6 2.4 5 5 0 01-1.4 1.6 5.7 5.7 0 01-2.1 1 8.6 8.6 0 01-2.7.2 8.7 8.7 0 01-2.6-.6 5.8 5.8 0 01-2-1.3 5 5 0 01-1.1-1.8 5.8 5.8 0 01-.3-2.4 5.6 5.6 0 01.7-2.3 4.6 4.6 0 011.4-1.7 5.8 5.8 0 012-.8 8.3 8.3 0 012.4-.2h1l-.6 8.5h.4a3.3 3.3 0 002.3-.6 3.1 3.1 0 001-2.4 3.5 3.5 0 00-.3-2 4.2 4.2 0 00-1.2-1.3l1.6-1.4a5 5 0 011.7 2 6.6 6.6 0 01.4 3.1zm-10.8-.8a3 3 0 00.2 1.2 2.6 2.6 0 00.6 1 2.9 2.9 0 001 .7 3.8 3.8 0 001.2.3h.2L13 91h-.2a3.4 3.4 0 00-2.4.6 2.5 2.5 0 00-1 1.8z' />
      <path d='M16.5 78.4l-2.1-.6a89.3 89.3 0 0146.9-57.2l1 2a87.1 87.1 0 00-45.8 55.8z' />
      <path d='M79.3 23.3a6.3 6.3 0 01-5.4-.8Q71.5 21 70.4 17a12.8 12.8 0 01-.6-3.6 7.5 7.5 0 01.6-3 5.7 5.7 0 011.7-2.1A7.1 7.1 0 0174.7 7a6.5 6.5 0 013.7-.1 6.2 6.2 0 013 2l-1.9 1.8a4 4 0 00-1.7-1.5 3.6 3.6 0 00-2.5 0 3.7 3.7 0 00-2.5 2.1 5.4 5.4 0 000 3.7l.6 2.4a5.4 5.4 0 002 3.2 4.1 4.1 0 005.4-1 4.5 4.5 0 00.8-2.3l2.4.7a6.5 6.5 0 01-1.4 3.3 6.3 6.3 0 01-3.3 2z' />
    </RoundContainer>

    <path d='M96.9 82.5h2.2V118h-2.2z' />
    <path d='M80.2 99.1h35.5v2.2H80.2z' />
  </svg>
);

export default StampChirurgie;
