// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampChu = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M4.1 73.8L6 68.3a7.7 7.7 0 011.4-2.6 6 6 0 012.2-1.6 7.1 7.1 0 012.9-.5 11.5 11.5 0 013.5.7 11.7 11.7 0 013.3 1.6 7.2 7.2 0 012 2.2 6 6 0 01.7 2.6 7.6 7.6 0 01-.4 2.8l-1.9 5.6zm15.3-1a4 4 0 00-.2-3.2 4.6 4.6 0 00-2.8-2.2l-2.5-.8a4.7 4.7 0 00-3.6 0 4 4 0 00-2 2.4l-1 3 11.1 3.8z' />
      <path d='M28.7 59.1A5.9 5.9 0 0127 61a5 5 0 01-2 .9 5.9 5.9 0 01-2.3 0 8.6 8.6 0 01-2.5-1 8.7 8.7 0 01-2.1-1.7 6 6 0 01-1.2-2 4.9 4.9 0 01-.3-2.2 6 6 0 01.8-2.3 5.7 5.7 0 011.6-1.8 4.6 4.6 0 012-.8 5.7 5.7 0 012.2 0 8 8 0 012.3 1l.8.4-4.2 7.3.4.2a3.3 3.3 0 002.4.5 3.1 3.1 0 002-1.7 3.5 3.5 0 00.5-1.9 4.2 4.2 0 00-.5-1.8l2-.6a4.8 4.8 0 01.6 2.7 6.5 6.5 0 01-1 3zm-9.4-5.4a3.2 3.2 0 00-.4 1.2A2.7 2.7 0 0019 56a3 3 0 00.6 1 3.9 3.9 0 001 .8l.1.1 2.9-5-.2-.1a3.4 3.4 0 00-2.4-.5 2.5 2.5 0 00-1.7 1.4z' />
      <path d='M36.5 48.3a6 6 0 01-5 2.3l-.1-2.3a5.2 5.2 0 002-.3 4.1 4.1 0 001.6-1.2 2.7 2.7 0 00.8-1.5 1.4 1.4 0 00-.6-1.2 1.4 1.4 0 00-1-.4 2.2 2.2 0 00-1.3.7l-.8.7a5.9 5.9 0 01-2.8 1.6 3.1 3.1 0 01-2.6-.9 3.7 3.7 0 01-1-1.3 3.4 3.4 0 01-.3-1.5 4.2 4.2 0 01.3-1.6 6.1 6.1 0 011-1.5A6.2 6.2 0 0129 38a6.2 6.2 0 012.3-.4l.1 2.1a4.1 4.1 0 00-1.5.4 3.7 3.7 0 00-1.5 1 2.4 2.4 0 00-.7 1.5 1.4 1.4 0 00.5 1 1.2 1.2 0 001.1.4 3.2 3.2 0 001.3-.7l.9-.8a5.5 5.5 0 012.8-1.4 3.1 3.1 0 012.4.8 3.7 3.7 0 011 1.3A3.5 3.5 0 0138 45a4.7 4.7 0 01-.4 1.7 7 7 0 01-1 1.7z' />
      <path d='M50.8 35.1a6.2 6.2 0 01-2.8 1.2 6.3 6.3 0 01-2.6-.2l.5-2.2a5.3 5.3 0 002 .2 4.1 4.1 0 001.8-.8 2.7 2.7 0 001.1-1.3 1.6 1.6 0 00-1.1-2 2.3 2.3 0 00-1.4.4l-1 .6a5.7 5.7 0 01-3 .8 3 3 0 01-2.4-1.5 3.7 3.7 0 01-.7-1.4 3.4 3.4 0 01.1-1.5 4.5 4.5 0 01.7-1.5 6.5 6.5 0 011.4-1.3 6 6 0 012.5-1.1 6.1 6.1 0 012.3 0l-.4 2.2a4 4 0 00-1.6 0 3.8 3.8 0 00-1.6.6 2.4 2.4 0 00-1 1.3 1.4 1.4 0 00.2 1.2 1.3 1.3 0 001 .6 3.3 3.3 0 001.4-.4l1-.5a5.4 5.4 0 013.1-.8 3.1 3.1 0 012.2 1.4 3.7 3.7 0 01.6 1.5 3.4 3.4 0 010 1.6 4.4 4.4 0 01-.8 1.6 6.8 6.8 0 01-1.5 1.3z' />
      <path d='M53 18.6l2.3-1 .9 1.8a3.4 3.4 0 01.6-2.1 3.8 3.8 0 011.7-1.4 4.3 4.3 0 014 0 7.7 7.7 0 013.1 3.8 7.7 7.7 0 01.8 4.8 4.3 4.3 0 01-2.6 3 3.8 3.8 0 01-2.2.3 3.5 3.5 0 01-2-1l2.7 6.2-2.3 1.1zm9.2 7.2a2.8 2.8 0 001.5-1.7 3.2 3.2 0 00-.2-2.4l-.8-1.9a3.2 3.2 0 00-1.7-1.7 2.8 2.8 0 00-2.3 0 3.3 3.3 0 00-1.6 1.4 1.8 1.8 0 00-.1 1.7l1.7 3.9a1.8 1.8 0 001.4 1 3.2 3.2 0 002-.3z' />
      <path d='M76.7 22.8a5.9 5.9 0 01-2.4.2 4.8 4.8 0 01-2-.7 5.7 5.7 0 01-1.7-1.7 8.9 8.9 0 01-1-2.4 8.5 8.5 0 01-.4-2.7 6 6 0 01.5-2.3 4.8 4.8 0 011.3-1.7 5.8 5.8 0 012.2-1 5.6 5.6 0 012.4-.2 4.7 4.7 0 012 .7 5.8 5.8 0 011.5 1.6 7.9 7.9 0 011 2.2l.3 1-8.1 2.3v.4a3.3 3.3 0 001.5 2 3.1 3.1 0 002.6.2 3.5 3.5 0 001.7-1A4.3 4.3 0 0079 18l1.9 1a4.9 4.9 0 01-1.5 2.3 6.4 6.4 0 01-2.7 1.5zM73.2 9.2L71.4 9l1-4.7 2.4.5zm.5 3.2a3.1 3.1 0 00-1 .5 2.7 2.7 0 00-.8 1 3 3 0 00-.3 1 3.8 3.8 0 00.2 1.4v.2l5.5-1.6-.1-.2a3.4 3.4 0 00-1.3-2 2.5 2.5 0 00-2.2-.3z' />
      <path d='M90.7 20.4a6.1 6.1 0 01-2.4-.3 4.7 4.7 0 01-1.9-1.1 5.7 5.7 0 01-1.2-2 8.6 8.6 0 01-.6-2.6 9 9 0 01.2-2.7 6 6 0 011-2.1 4.5 4.5 0 011.6-1.4 6 6 0 012.3-.6 5.2 5.2 0 013 .6 4.7 4.7 0 012 2l-2 1.2a2.5 2.5 0 00-2.8-1.7 2.6 2.6 0 00-2 1 3.5 3.5 0 00-.6 2.3l.2 2.2a3.6 3.6 0 00.8 2.3 2.6 2.6 0 002.2.7 2.7 2.7 0 001.8-.7 3.9 3.9 0 001-1.6l2 1a5 5 0 01-1.7 2.4 5.4 5.4 0 01-3 1z' />
      <path d='M99.4 7.5l2.6.1-.3 12.3H99zm1.4-2.1a1.6 1.6 0 01-1.2-.4 1.3 1.3 0 01-.3-1v-.4a1.4 1.4 0 01.4-1 1.6 1.6 0 011.1-.3 1.6 1.6 0 011.2.4 1.4 1.4 0 01.3 1V4a1.4 1.4 0 01-.3.9 1.5 1.5 0 01-1.2.4z' />
      <path d='M115.4 21.7a2 2 0 01-1.4-.8 2.6 2.6 0 01-.4-1.6h-.1a3 3 0 01-1.6 1.5 4.2 4.2 0 01-2.4.2 4 4 0 01-2.7-1.5 3.6 3.6 0 01-.5-2.8 3.1 3.1 0 011.8-2.5 7 7 0 014-.2l2.2.4.1-1a2.3 2.3 0 00-.3-1.9 2.6 2.6 0 00-1.7-1 3 3 0 00-1.9.2 4.3 4.3 0 00-1.3 1l-1.3-1.7a4.9 4.9 0 012-1.4 5.6 5.6 0 013-.1 5.5 5.5 0 013.4 1.7 4 4 0 01.7 3.4l-1 6 1.2.3-.4 2.1zm-4.8-2.5a3.5 3.5 0 002-.2 1.7 1.7 0 001-1.3l.4-1.7-2.1-.4c-1.8-.3-2.8 0-3 1.2v.4a1.4 1.4 0 00.3 1.3 2.6 2.6 0 001.4.6z' />
      <path d='M122.9 23.9a2.6 2.6 0 01-1.7-1.3 2.5 2.5 0 010-2L126 6.5l2.4.8-4.9 14.6 1.6.5-.7 2z' />
      <path d='M133 14.5l2.3 1-5 11.2-2.4-1zm2-1.5a1.5 1.5 0 01-1-.8 1.3 1.3 0 01.2-1l.1-.4a1.3 1.3 0 01.7-.7 1.6 1.6 0 011.3.1 1.5 1.5 0 01.9.8 1.3 1.3 0 01-.1 1l-.2.4a1.3 1.3 0 01-.7.7 1.5 1.5 0 01-1.2-.1z' />
      <path d='M137.9 31a6.2 6.2 0 01-2.3-2 6.5 6.5 0 01-.9-2.5l2.2-.5a5.3 5.3 0 00.7 1.9 4 4 0 001.4 1.3 2.6 2.6 0 001.7.5 1.4 1.4 0 001-.8 1.4 1.4 0 00.2-1 2.2 2.2 0 00-.9-1.2l-.9-.7a5.8 5.8 0 01-2-2.4 3.1 3.1 0 01.4-2.8 3.8 3.8 0 011-1.2 3.4 3.4 0 011.4-.6 4.3 4.3 0 011.7.1 6.3 6.3 0 011.7.7 5.6 5.6 0 013 4l-2.1.5a4.1 4.1 0 00-.6-1.4 3.8 3.8 0 00-1.3-1.3 2.4 2.4 0 00-1.6-.4 1.4 1.4 0 00-1 .7 1.3 1.3 0 00-.1 1.2 3.2 3.2 0 001 1.1l.9.7a5.5 5.5 0 012 2.5 3.1 3.1 0 01-.4 2.6 3.5 3.5 0 01-2.6 1.8 4.4 4.4 0 01-1.7 0 6.8 6.8 0 01-2-.8z' />
      <path d='M148 37.6a2.7 2.7 0 01-1.1-1.8 2.6 2.6 0 01.7-2l4.6-5.9-1.5-1.2 1.3-1.6.8.6a1.2 1.2 0 00.9.3 1.4 1.4 0 00.8-.5l1.3-1.7 1.8 1.4-2 2.6 2 1.6-1.4 1.7-2-1.6-5 6.4 2 1.4-1.4 1.7z' />
      <path d='M156 45.4a5.8 5.8 0 01-1.3-2 5 5 0 01-.3-2.2 5.8 5.8 0 01.7-2.2 8.3 8.3 0 011.6-2.1 8.6 8.6 0 012.2-1.6 6 6 0 012.3-.6 4.7 4.7 0 012.1.4 5.8 5.8 0 012 1.4 5.5 5.5 0 011.3 2 4.7 4.7 0 01.3 2.2 5.8 5.8 0 01-.7 2 7.9 7.9 0 01-1.5 2l-.7.6-5.9-6.1-.2.3a3.3 3.3 0 00-1.1 2.2 3.1 3.1 0 001 2.3 3.5 3.5 0 001.6 1 4.3 4.3 0 002 0v2.2a4.8 4.8 0 01-2.7-.2 6.4 6.4 0 01-2.7-1.6zm7.8-7.5a3 3 0 00-1-.7 2.6 2.6 0 00-1-.3 3 3 0 00-1.2.3 4 4 0 00-1.1.7l-.1.2 3.9 4.1.2-.2a3.4 3.4 0 001.1-2 2.5 2.5 0 00-.8-2.1z' />
      <path d='M164.3 55.8a6.1 6.1 0 01-1-2.8 6.5 6.5 0 01.2-2.7l2.2.6a5.5 5.5 0 00-.2 2 4.1 4.1 0 00.6 2 2.6 2.6 0 001.3 1.1 1.4 1.4 0 001.3-.2 1.4 1.4 0 00.7-.9 2.2 2.2 0 00-.3-1.4l-.5-1a5.8 5.8 0 01-.7-3 3.1 3.1 0 011.6-2.4 3.6 3.6 0 011.5-.5 3.3 3.3 0 011.5.1 4.3 4.3 0 011.4.8 6.1 6.1 0 011.2 1.4 6 6 0 011 2.6 6.1 6.1 0 01-.2 2.4l-2-.6a4 4 0 00.1-1.6 3.6 3.6 0 00-.6-1.7 2.4 2.4 0 00-1.2-1.1 1.4 1.4 0 00-1.2.2 1.3 1.3 0 00-.7 1 3.2 3.2 0 00.4 1.5l.5 1a5.5 5.5 0 01.6 3.1 3.1 3.1 0 01-1.5 2 3.8 3.8 0 01-1.6.7 3.6 3.6 0 01-1.5-.2 4.5 4.5 0 01-1.6-.9 7 7 0 01-1.3-1.5z' />
      <path d='M175 78a2 2 0 010-1.7 2.7 2.7 0 011.2-1.1V75a3 3 0 01-2-.7 4.3 4.3 0 01-1.4-2 4.1 4.1 0 010-3.1 3.6 3.6 0 012.2-1.8 3.2 3.2 0 013 .3 6.9 6.9 0 012.2 3.4l.7 2.2 1-.4a2.4 2.4 0 001.5-1.1 2.6 2.6 0 000-2 3.1 3.1 0 00-1-1.6 4.3 4.3 0 00-1.5-.7l.8-1.9a5 5 0 012.2 1.1 5.6 5.6 0 011.5 2.6 5.5 5.5 0 01.2 3.8 4 4 0 01-2.7 2.2l-5.8 2 .4 1.3-2 .6zm0-5.5a3.5 3.5 0 001 1.7 1.6 1.6 0 001.6.3l1.7-.6-.7-2c-.6-1.7-1.4-2.4-2.4-2l-.4.1a1.4 1.4 0 00-1 1 2.5 2.5 0 00.1 1.5z' />
      <path d='M176.6 86.8a6.2 6.2 0 01.1-3 6.4 6.4 0 011.3-2.3l1.8 1.4a5.4 5.4 0 00-1 1.7 4 4 0 00-.1 2 2.7 2.7 0 00.7 1.6 1.4 1.4 0 001.3.3 1.4 1.4 0 001-.5 2.2 2.2 0 00.2-1.5v-1.1a5.8 5.8 0 01.5-3.1 3 3 0 012.4-1.5 3.7 3.7 0 011.6 0 3.4 3.4 0 011.3.7 4.5 4.5 0 011 1.3 6.2 6.2 0 01.6 1.7 6.1 6.1 0 010 2.8 6.3 6.3 0 01-1 2.1l-1.8-1.3a4 4 0 00.7-1.4 3.8 3.8 0 00.1-1.8 2.5 2.5 0 00-.7-1.5 1.4 1.4 0 00-1.2-.3 1.3 1.3 0 00-1 .7 3.3 3.3 0 00-.2 1.5v1.1a5.4 5.4 0 01-.6 3.1 3.1 3.1 0 01-2.2 1.4 3.7 3.7 0 01-1.6 0 3.6 3.6 0 01-1.4-.8 4.4 4.4 0 01-1-1.4 6.7 6.7 0 01-.8-1.9z' />
      <path d='M177.8 99.4a6.4 6.4 0 01.5-3 6.5 6.5 0 011.6-2l1.6 1.6a5.5 5.5 0 00-1.3 1.6 4.2 4.2 0 00-.4 2 2.6 2.6 0 00.5 1.6 1.4 1.4 0 001.2.5 1.4 1.4 0 001-.4 2.2 2.2 0 00.5-1.3l.2-1.2a5.8 5.8 0 011-3 3.1 3.1 0 012.5-1.1 3.7 3.7 0 011.6.3 3.3 3.3 0 011.2.9 4.1 4.1 0 01.8 1.4 6 6 0 01.3 1.9 6 6 0 01-.5 2.7 6.3 6.3 0 01-1.4 1.9l-1.5-1.6a4.2 4.2 0 001-1.2 3.6 3.6 0 00.3-1.8 2.4 2.4 0 00-.5-1.6 1.4 1.4 0 00-1-.4 1.3 1.3 0 00-1.1.4 3.2 3.2 0 00-.5 1.5l-.1 1.1a5.6 5.6 0 01-1.1 3 3.1 3.1 0 01-2.4 1 3.5 3.5 0 01-2.9-1.2 4.4 4.4 0 01-.8-1.5 6.8 6.8 0 01-.3-2z' />
      <path d='M176.8 113a5.8 5.8 0 01.8-2.1 4.7 4.7 0 011.6-1.5 5.7 5.7 0 012.2-.8 8.2 8.2 0 012.7 0 8.3 8.3 0 012.5 1 5.9 5.9 0 011.8 1.4 4.8 4.8 0 011 2 6.3 6.3 0 01-.8 4.6 5 5 0 01-1.6 1.5 6.1 6.1 0 01-2.2.8 8.5 8.5 0 01-2.7-.1 8.3 8.3 0 01-2.5-.9 5.9 5.9 0 01-1.8-1.4 5 5 0 01-1-2 5.9 5.9 0 010-2.4zm2 .5a2.9 2.9 0 00.5 2.2 3.3 3.3 0 002.2 1.2l2 .4a3.3 3.3 0 002.6-.4 2.8 2.8 0 001.1-2 2.9 2.9 0 00-.4-2.2 3.3 3.3 0 00-2.2-1.2l-2-.4a3.3 3.3 0 00-2.6.4 3 3 0 00-1.1 2z' />
      <path d='M173.2 127a5.8 5.8 0 011.2-2.1 4.7 4.7 0 011.8-1.2 5.7 5.7 0 012.2-.4 8.6 8.6 0 012.7.5 9.1 9.1 0 012.4 1.2 6 6 0 011.5 1.7 4.7 4.7 0 01.7 2 6 6 0 01-.3 2.4 5.1 5.1 0 01-1.8 2.6 4.8 4.8 0 01-2.6 1l-.3-2.4a2.7 2.7 0 001.6-.4 2.7 2.7 0 001-1.5 2.6 2.6 0 00-.1-2.3 3.5 3.5 0 00-2-1.4l-2-.7a3.5 3.5 0 00-2.5 0 2.6 2.6 0 00-1.5 1.7 2.8 2.8 0 000 1.9 3.7 3.7 0 001 1.5l-1.6 1.5a5 5 0 01-1.6-2.5 5.3 5.3 0 01.2-3.1z' />
      <path d='M181.2 140.6l-1.2 2.2-11-5.6 1.2-2.2zm1.3 2.1a1.6 1.6 0 011-.9 1.3 1.3 0 011 .1l.3.2a1.3 1.3 0 01.7.8 1.6 1.6 0 01-.2 1.2 1.6 1.6 0 01-.9.9 1.4 1.4 0 01-1-.1l-.4-.2a1.4 1.4 0 01-.7-.8 1.5 1.5 0 01.2-1.2z' />
      <path d='M163.9 145.4a6 6 0 011.7-1.7 5 5 0 012.1-.7 5.8 5.8 0 012.3.3 9 9 0 014.3 3 5.7 5.7 0 011 2.2 4.9 4.9 0 010 2.2 5.8 5.8 0 01-1 2.2 5.6 5.6 0 01-1.7 1.6 4.7 4.7 0 01-2 .6 5.8 5.8 0 01-2.3-.2 8 8 0 01-2.1-1.1l-.8-.6 4.9-6.9-.3-.2a3.3 3.3 0 00-2.4-.7 3.1 3.1 0 00-2.1 1.5 3.5 3.5 0 00-.7 1.8 4.3 4.3 0 00.3 1.9l-2.1.3a5 5 0 01-.3-2.6 6.6 6.6 0 011.2-2.9zm8.8 6.3a3 3 0 00.5-1.1 2.6 2.6 0 000-1.2 2.9 2.9 0 00-.4-1 4.2 4.2 0 00-1-1l-.1-.1-3.3 4.7.2.1a3.4 3.4 0 002.3.8 2.5 2.5 0 001.8-1.2zm2.5 2l1.5-.8 2.2 4.2-2.2 1.2z' />
      <path d='M155.5 155.8a6.2 6.2 0 012.5-1.7 6.4 6.4 0 012.6-.4v2.3a5.3 5.3 0 00-2 .2 4 4 0 00-1.7 1 2.8 2.8 0 00-.8 1.6 1.5 1.5 0 00.5 1.3 1.4 1.4 0 001 .4 2.3 2.3 0 001.3-.6l.9-.8a5.7 5.7 0 012.8-1.4 3 3 0 012.6 1 3.8 3.8 0 011 1.4 3.4 3.4 0 01.2 1.5 4 4 0 01-.5 1.5 5.8 5.8 0 01-1.1 1.6 5.5 5.5 0 01-4.6 2v-2.2a4 4 0 001.6-.3 3.7 3.7 0 001.5-1 2.5 2.5 0 00.8-1.4 1.4 1.4 0 00-.5-1.1 1.3 1.3 0 00-1-.4 3.2 3.2 0 00-1.4.7l-1 .7a5.5 5.5 0 01-2.8 1.3 3.2 3.2 0 01-2.4-1 3.8 3.8 0 01-1-1.4 3.5 3.5 0 01-.1-1.5 4.5 4.5 0 01.4-1.7 6.4 6.4 0 011.2-1.7z' />
      <path d='M136.3 171a2 2 0 011.6-.3 2.6 2.6 0 011.4 1v-.1a3 3 0 01.2-2.2 4.3 4.3 0 011.6-1.7 4 4 0 013-.8 3.6 3.6 0 012.3 1.7 3.1 3.1 0 01.4 3 6.8 6.8 0 01-2.8 2.9l-1.9 1.2.6.8a2.3 2.3 0 001.4 1.2 2.6 2.6 0 002-.5 3 3 0 001.2-1.4 4.3 4.3 0 00.3-1.6l2 .3a5 5 0 01-.5 2.4 6.6 6.6 0 01-5.8 3.2 4 4 0 01-2.7-2l-3.4-5.2-1 .7-1.2-1.8zm5.2-1.3a3.4 3.4 0 00-1.3 1.5 1.7 1.7 0 000 1.6l1 1.5 2-1.1c1.4-1 2-2 1.3-2.9l-.3-.3a1.4 1.4 0 00-1.1-.8 2.5 2.5 0 00-1.5.5z' />
      <path d='M125.5 178a5.5 5.5 0 01.1-1 3 3 0 01.4-1 3.1 3.1 0 01.7-.8 4.3 4.3 0 011.2-.6 3.6 3.6 0 013.2 0 5.2 5.2 0 012.3 2.9l2.9 7.3-2.4 1-2.8-7c-.7-1.9-1.8-2.4-3.2-1.9a4 4 0 00-.9.5 2.7 2.7 0 00-.6.7 2.2 2.2 0 00-.3.8 2 2 0 00.1 1l3 7.7-2.4 1-4.5-11.5 2.4-1z' />
      <path d='M104.6 180.2a6.3 6.3 0 015.3 1.8 9.7 9.7 0 012.3 6.2 12.8 12.8 0 01-.2 3.7 7.5 7.5 0 01-1.2 2.7 5.9 5.9 0 01-2 1.8 7 7 0 01-2.9.8 6.5 6.5 0 01-3.6-.7 6.2 6.2 0 01-2.5-2.5l2.1-1.4a4 4 0 001.5 1.8 3.6 3.6 0 002.4.5 3.7 3.7 0 002.8-1.5 5.5 5.5 0 00.9-3.7l-.2-2.5a5.5 5.5 0 00-1.4-3.5 3.7 3.7 0 00-3-1.1 3.6 3.6 0 00-2.4 1 4.6 4.6 0 00-1.3 2l-2.2-1.2a6.7 6.7 0 012.1-3 6.4 6.4 0 013.5-1.2z' />
      <path d='M83.7 187l7.4.9 1-7.2 2.6.4-2.1 16.3-2.7-.3 1-6.9-7.5-1-.9 7-2.6-.4 2.1-16.3 2.6.3z' />
      <path d='M69.2 193l3.2-9.5a5.2 5.2 0 00.3-3.3 4 4 0 00-5.2-1.8 5.2 5.2 0 00-1.8 2.8l-3.3 9.6-2.4-.8 3.1-9.2a13.3 13.3 0 011.3-2.9 5.4 5.4 0 011.7-1.7 4.5 4.5 0 012.3-.6 8.7 8.7 0 012.8.5 8.6 8.6 0 012.6 1.3 4.3 4.3 0 011.4 1.8 5.2 5.2 0 01.3 2.5 12.8 12.8 0 01-.7 3l-3.1 9.3z' />
      <path d='M51.5 176.5a89.2 89.2 0 01-43.1-76A91 91 0 019 88.8l2.2.3a88.4 88.4 0 00-.7 11.2 87 87 0 0042 74.2z' />
    </RoundContainer>
    <path d='M95 82.2h2.2v35.5H95z' />
    <path d='M78.3 98.8h35.5v2.2H78.3z' />
  </svg>
);

export default StampChu;
