import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroOffset from '../components/HeroOffset';
import { h1Style, introStyle } from '../styles/global';
import colors from '../styles/colors';
import IconArrow from '../images/IconArrow';
import breakpointsRange from '../utils/breakpointsRange';
import breakpoints from '../styles/breakpoints';
import { fontSizes } from '../styles/typography';
import { greaterThan } from '../utils/mediaQuery';
import { speed, easing } from '../styles/animation';
import HomeImplicationSection from '../views/HomeImplicationSection';
import CliniqueList from '../components/CliniqueList';
import StampChirurgie from '../images/StampChirurgie';

const CTASection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));

  max-width: 1440px;
  margin: auto;
  padding: 0 28px;

  ${breakpointsRange(
    [
      { prop: 'gap', sizes: [28, 64, 104, 120] },
      { prop: 'marginTop', sizes: [48, 112] },
      { prop: 'marginBottom', sizes: [88, 168] },
    ],
    breakpoints.spacings
  )};

  ${greaterThan(breakpoints.spacings[0])} {
    padding: 0 80px;
  }

  ${greaterThan(breakpoints.spacings[1])} {
    padding: 0 80px;
  }

  ${greaterThan(breakpoints.spacings[2])} {
    padding: 0 120px;
  }
`;

const CTATitleWrapper = styled.div`
  flex-grow: 1;
`;

const CTATitle = styled.span`
  display: block;

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [20, 30],
        base: fontSizes.body,
        unit: 'em',
      },
    ],
    breakpoints.fonts
  )};
`;

const CTASubTitle = styled.span`
  display: block;
`;

const CTAArrowWrapper = styled.span`
  display: block;

  transform: translateX(-8px);

  transition: transform ${speed.fast} ${easing.inQuint};

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [12, 15],
        base: fontSizes.body,
        unit: 'em',
      },
    ],
    breakpoints.fonts
  )};

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

const CTACard = styled(Link)`
  display: flex;
  align-items: center;

  color: ${colors.black};
  text-decoration: none;

  background-color: ${colors.veryLightPink};

  :hover,
  :focus {
    ${CTAArrowWrapper} {
      transform: translateX(0);

      transition: transform ${speed.fast} ${easing.outQuint};

      @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
  }

  ${breakpointsRange(
    [
      { prop: 'padding', sizes: [20, 40] },
      { prop: 'paddingRight', sizes: [12, 40] },
      { prop: 'minHeight', sizes: [75, 140] },
    ],
    breakpoints.spacings
  )};
`;

const CTACardBlue = css`
  color: ${colors.white};

  background-color: ${colors.azure};
`;

const IndexPage = ({ data, location }) => {
  const {
    headerImage: {
      childImageSharp: { fluid: headerImage },
    },
  } = data;

  return (
    <Layout hasNoHeaderSpacer location={location}>
      <SEO description='Nos spécialistes en chirurgie buccale et maxillo-faciale des cliniques Maxillo Québec Lebourgneuf et Lévis offrent la meilleure prestation de soins dentaires chirurgicaux.' />

      <HeroOffset
        img={headerImage}
        stamp={<StampChirurgie />}
        stampAlwaysBottom
        id='mission'
      >
        <h2 css={h1Style}>Notre première&nbsp;mission</h2>

        <p css={introStyle}>
          Offrir la meilleure prestation de soins et services en chirurgie
          buccale et maxillo-faciale avec compétence, compassion et continuité à
          chacun des patients qui nous sollicite et nous honore de sa confiance.
        </p>
      </HeroOffset>

      <CTASection>
        <CTACard to='/espace-patient' css={[CTACardBlue]}>
          <CTATitleWrapper>
            <CTATitle>Espace patient</CTATitle>

            <CTASubTitle>Recevez des soins chez nous</CTASubTitle>
          </CTATitleWrapper>

          <CTAArrowWrapper>
            <IconArrow />
          </CTAArrowWrapper>
        </CTACard>

        <CTACard to='/espace-professionnel'>
          <CTATitleWrapper>
            <CTATitle>Espace pro</CTATitle>

            <CTASubTitle>Référez vos patients chez nous</CTASubTitle>
          </CTATitleWrapper>

          <CTAArrowWrapper>
            <IconArrow />
          </CTAArrowWrapper>
        </CTACard>
      </CTASection>

      <HomeImplicationSection />

      <CliniqueList brief stamped />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    headerImage: file(name: { eq: "img-header-home-mission-1" }) {
      ...HeroOffsetPictureData
    }
  }
`;
