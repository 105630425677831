// vendors
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { introStyle, h1Style } from '../../styles/global';

import logoChu from '../../images/logo-chu-de-quebec.svg';
import logoUlaval from '../../images/logo-universite-laval.svg';
import logoMereEnfant from '../../images/logo-centre-mere-enfant.svg';
import {
  Container,
  Image,
  LogoContainer,
  TextFirstPart,
  TextSecondPart,
  TextPart,
  ImageWrapper,
  LogoWrapper,
  LogoList,
  StampWrapper,
} from './HomeImplicationSection.styles';
import StampChu from '../../images/StampChu';
import { greaterThanCondition } from '../../utils/mediaQuery';

const query = graphql`
  query {
    image: file(name: { eq: "img-header-home-implication-1" }) {
      childImageSharp {
        fluid(maxWidth: 720, maxHeight: 720, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const HomeImplicationSection = () => {
  const data = useStaticQuery(query);

  const {
    image: {
      childImageSharp: { fluid },
    },
  } = data;

  const source = {
    ...fluid,
    sizes: `${greaterThanCondition(768)} ${
      (660 / 1440) * 100
    }vw, ${greaterThanCondition(1440)} ${
      (720 / 1560) * 100
    }vw, ${greaterThanCondition(1560)} 720px, ${(740 / 768) * 100}vw`,
  };

  return (
    <Container id='association'>
      <ImageWrapper>
        <Image fluid={source} />

        <StampWrapper>
          <StampChu />
        </StampWrapper>
      </ImageWrapper>

      <TextPart css={TextFirstPart}>
        <h2 css={h1Style}>L’implication de nos spécialistes</h2>

        <p css={introStyle}>
          La majorité de nos spécialistes de la clinique Maxillo Québec sont
          associés au Centre Hospitalier Universitaire de Québec (CHU).
        </p>
      </TextPart>

      <TextPart css={TextSecondPart}>
        <p>
          Nos chirurgiens spécialistes de la clinique Maxillo Québec participent
          à la formation chirurgicale des étudiants de la Faculté de médecine
          dentaire et sont étroitement impliqués au niveau du programme de
          spécialisation en chirurgie buccale et maxillo-faciale de l’Université
          Laval.
        </p>

        <p>
          Nos chirurgiens spécialistes ont une mission de recherche afin de
          faire rayonner et avancer leur profession tant au provincial qu’à
          l’international.
        </p>
      </TextPart>

      <LogoContainer>
        <LogoList>
          <LogoWrapper
            style={{ flexBasis: `${(152 / 187) * 100}%`, maxWidth: '152px' }}
          >
            <img src={logoChu} alt='' role='presentation' />
          </LogoWrapper>

          <LogoWrapper
            style={{ flexBasis: `${(187 / 187) * 100}%`, maxWidth: '187px' }}
          >
            <img src={logoUlaval} alt='' role='presentation' />
          </LogoWrapper>

          <LogoWrapper
            style={{ flexBasis: `${(157 / 187) * 100}%`, maxWidth: '157px' }}
          >
            <img src={logoMereEnfant} alt='' role='presentation' />
          </LogoWrapper>
        </LogoList>
      </LogoContainer>
    </Container>
  );
};

export default HomeImplicationSection;
