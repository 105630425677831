// vendors
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { between } from 'polished';
import breakpointsRange from '../../utils/breakpointsRange';
import breakpoints from '../../styles/breakpoints';
import { greaterThan, lessThan } from '../../utils/mediaQuery';
import colors from '../../styles/colors';

const selfBreakpoints = {
  sm: breakpoints.spacings[0], // 768
  md: breakpoints.spacings[1], // 992
  lg: breakpoints.spacings[2], // 1280
  xlg: 1440, // 1440
  xxlg: 1560, // 1560
};

const layoutBreakpoint = selfBreakpoints.sm;

export const Container = styled.section`
  display: grid;
  grid-template-rows: 4fr;
  grid-template-columns: 1fr;

  max-width: 1440px;
  margin: auto;

  ${greaterThan(layoutBreakpoint)} {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: ${660 / 1440}fr ${780 / 1440}fr;
  }

  ::before {
    display: block;
    grid-row: 3 / span 2;
    grid-column: 1 / span 1;

    ${greaterThan(layoutBreakpoint)} {
      grid-row: 2 / span 2;
      grid-column: 1 / span 2;
    }

    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);

    background-color: ${colors.wildSand};

    content: '';
  }

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [88, 160] },
      { prop: 'marginBottom', sizes: [96, 256] },
    ],
    breakpoints.spacings
  )};
`;

export const ImageWrapper = styled.div`
  position: relative;

  grid-row: 1 / span 1;
  grid-column: 1 / span 1;

  align-self: start;

  margin-right: 28px;

  margin-bottom: 96px;

  ${greaterThan(layoutBreakpoint)} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;

    margin-right: 0;
  }

  ${greaterThan(1440)} {
    margin-left: ${between('0px', '-60px', '1440px', '1560px')};
  }

  ${greaterThan(1560)} {
    margin-left: -60px;
  }
`;

export const Image = styled(Img)``;

export const LogoContainer = styled.div`
  grid-row: 4 / span 1;
  grid-column: 1 / span 1;
  justify-self: stretch;

  margin: 56px 28px;

  ${greaterThan(layoutBreakpoint)} {
    grid-row: 3 / span 1;
    grid-column: 1 / span 1;
  }

  ${greaterThan(breakpoints.spacings[0])} {
    margin: 32px 40px;
    margin-right: 0;
  }

  ${greaterThan(breakpoints.spacings[1])} {
    margin: 56px 80px 64px;
    margin-right: 0;
  }

  ${greaterThan(breakpoints.spacings[2])} {
    margin: 56px 120px 104px;
    margin-right: 0;
  }

  ${greaterThan(1440)} {
    margin-left: ${between('120px', '-60px', '1440px', '1560px')};
  }

  ${greaterThan(1560)} {
    margin-left: -60px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex: 0 1;
  align-items: flex-end;
  min-width: 96px;
  max-width: 184px;

  img {
    width: 100%;
  }
`;

export const LogoList = styled.div`
  display: flex;

  justify-content: center;

  margin: -20px;

  ${LogoWrapper} {
    margin: 20px;
  }

  ${lessThan(576)} {
    flex-flow: column;
    align-items: center;

    ${LogoWrapper} {
      width: 140px;
    }
  }

  ${greaterThan(576)} {
    flex-wrap: wrap;
  }

  ${greaterThan(breakpoints.spacings[0])} {
    margin: -20px;

    ${LogoWrapper} {
      margin: 20px;
    }
  }

  ${greaterThan(breakpoints.spacings[1])} {
    flex-wrap: nowrap;
    margin: -20px;

    ${LogoWrapper} {
      margin: 20px;
    }
  }

  ${greaterThan(breakpoints.spacings[2])} {
    margin: -20px -28px;

    ${LogoWrapper} {
      margin: 20px 28px;
    }
  }

  ${greaterThan(1440)} {
    margin: -20px ${between('-28px', '-56px', '1440px', '1560px')};

    ${LogoWrapper} {
      margin: 20px ${between('28px', '56px', '1440px', '1560px')};
    }
  }

  ${greaterThan(1560)} {
    margin: -20px -56px;

    ${LogoWrapper} {
      margin: 20px 56px;
    }
  }
`;

export const TextPart = styled.div`
  margin: 0 28px;

  ${greaterThan(breakpoints.spacings[0])} {
    margin: 32px 40px;
  }

  ${greaterThan(breakpoints.spacings[1])} {
    margin: 64px 80px;
  }

  ${greaterThan(breakpoints.spacings[2])} {
    /* 104 ou 120 */
    margin: 104px 120px;
  }
`;

export const TextFirstPart = css`
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;

  margin-bottom: 32px;

  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }

  ${greaterThan(layoutBreakpoint)} {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
  }
`;

export const TextSecondPart = css`
  grid-row: 3 / span 1;
  grid-column: 1 / span 1;

  margin-top: 48px;
  margin-bottom: 56px;

  ${greaterThan(layoutBreakpoint)} {
    grid-row: 2 / span 2;
    grid-column: 2 / span 1;

    align-self: center;
  }
`;

export const StampWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 28px;

  width: 120px;

  color: ${colors.azure};

  transform: translateY(-40px);

  ${greaterThan(breakpoints.spacings[0])} {
    left: 40px;
  }

  ${greaterThan(breakpoints.spacings[1])} {
    left: 80px;
  }

  ${greaterThan(breakpoints.spacings[2])} {
    left: 80px;
  }

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [120, 184],
      },
    ],
    breakpoints.fonts
  )}
`;
