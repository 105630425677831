// vendors
import styled, { css } from 'styled-components';
import { rem, between } from 'polished';
import Img from 'gatsby-image';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

export const selfBreakpoints = {
  sm: breakpoints[2],
  md: breakpoints[4],
  lg: breakpoints[6],
};

export const Container = styled.header`
  max-width: 1440px;
  margin: 0 auto;
  ${responsiveStyle('marginBottom', [64, 168], breakpoints.spacings)};

  ${greaterThan(selfBreakpoints.md)} {
    display: flex;

    align-items: flex-end;
  }
`;

export const PictureContainer = styled.div`
  position: relative;

  order: 1;

  margin-bottom: ${rem(96)};
  margin-left: ${rem(28)};

  ${greaterThan(selfBreakpoints.sm)} {
    margin-left: ${rem(80)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    flex: 1 1 ${(660 / 1440) * 100}%;
    max-width: 660px;
    max-height: 660px;
    margin-bottom: 0;
    margin-left: 0;
  }

  ${greaterThan(1440)} {
    flex: 0 0 ${between('660px', '720px', '1440px', '1560px')};
    max-width: ${between('660px', '720px', '1440px', '1560px')};
    max-height: ${between('660px', '720px', '1440px', '1560px')};
    margin-right: ${between('0px', '-60px', '1440px', '1560px')};
  }

  ${greaterThan(1560)} {
    max-width: 720px;
    max-height: 720px;
    margin-right: ${rem(-60)};
  }
`;

export const StyledPicture = styled(Img)`
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.div`
  ${greaterThan(selfBreakpoints.sm)} {
    margin-top: 110px;
  }

  ${greaterThan(selfBreakpoints.md)} {
    flex: 1 1 ${(780 / 1440) * 100}%;
    max-width: 780px;
  }
`;

export const TextWrapper = styled.div`
  padding: 0 ${rem(28)};

  ${greaterThan(selfBreakpoints.sm)} {
    padding: 0 ${rem(80)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    margin: ${rem(40)};
    padding: 0 ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.lg)} {
    padding: 0 ${rem(80)};
  }
`;

export const StampWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: ${rem(28)};

  transform: translateY(-35%);

  ${responsiveStyle('width', [120, 190], breakpoints.fonts)}

  ${({ stampAlwaysBottom }) =>
    stampAlwaysBottom &&
    css`
      ${responsiveStyle('right', [28, 80, 80, 120], breakpoints.spacings)}
    `};

  ${({ stampAlwaysBottom }) =>
    !stampAlwaysBottom &&
    css`
      ${greaterThan(selfBreakpoints.md)} {
        top: 50%;
        right: 100%;

        transform: translateX(43%);
      }
    `};
`;
