// vendors
import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import {
  Container,
  PictureContainer,
  TextContainer,
  TextWrapper,
  StyledPicture,
  StampWrapper,
  selfBreakpoints,
} from './HeroOffset.styles';

const HeroOffset = ({ children, img, stamp, stampAlwaysBottom }) => {
  const source = {
    ...img,
    sizes: `(min-width: ${
      selfBreakpoints.md
    }px) 50vw, (min-width: 1560px) 720px, ${((320 - 28) / 320) * 100}vw`,
  };
  return (
    <Container>
      <PictureContainer>
        <StyledPicture fluid={source} />

        {stamp && (
          <StampWrapper stampAlwaysBottom={stampAlwaysBottom}>
            {stamp}
          </StampWrapper>
        )}
      </PictureContainer>

      <TextContainer>
        <TextWrapper>{children}</TextWrapper>
      </TextContainer>
    </Container>
  );
};

HeroOffset.propTypes = {
  children: PropTypes.node.isRequired,
  stamp: PropTypes.node,
  stampAlwaysBottom: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
  }).isRequired,
};

HeroOffset.defaultProps = {
  stamp: undefined,
  stampAlwaysBottom: false,
};

export default HeroOffset;

export const query = graphql`
  fragment HeroOffsetPictureData on File {
    childImageSharp {
      fluid(maxWidth: 720, maxHeight: 720, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
